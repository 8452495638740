import React, { useState } from 'react';

import { ReactComponent as ArrowIcon } from '../assets/images/arrow.svg';
import { FormattedMessage } from "react-intl";

function Footer({ setLocale }) {
  const [open, setOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState('en');
  const [langs, setLangs] = useState([
    'jp',
    'es',
    'ru',
  ]);

  const toggleHandle = () => setOpen(!open);

  const setLanguage = (lang) => {
    const currentLangs = langs.filter((item) => item !== lang);
    currentLangs.push(currentLang);
    setLangs(currentLangs);
    setCurrentLang(lang);
    setOpen(false);
    setLocale(lang);
  }

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="copyright">
          <FormattedMessage
            id="footer-copyright"
            values={{
              br: <br />,
            }}
          />
        </div>

        <div className="menu">
          <a href="https://pixonic.com/en?wr_terms"><FormattedMessage id="footer-terms-of-use" /></a>
          <a href="https://pixonic.com/en/?wr_privacy_policy"><FormattedMessage id="footer-privacy-policy" /></a>
          <a href="https://pixonic.com/en/?wr_cookies"><FormattedMessage id="footer-cookie-policy" /></a>
        </div>

        <div className="lang-switcher">
          {open && (
            <ul>
              {langs.map((lang, index) => <li key={index}><div className="lang" onClick={() => setLanguage(lang)}>{lang}</div></li>)}
            </ul>
          )}
          <div className="current-lang" onClick={toggleHandle}>
            <span>{currentLang}</span>
            <div className={open ? "icon open" : "icon"}>
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;