import React, { useEffect, useRef, useState } from 'react';
import Footer from "./components/Footer";
import Social from "./components/Social";
import { FormattedMessage, useIntl } from "react-intl";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/scss';

import { ReactComponent as SvgPrev } from './assets/images/arrow-left.svg';
import { ReactComponent as SvgNext } from './assets/images/arrow-right.svg';

import Logo from './assets/images/logo.svg';
import ImageNews2 from './assets/images/news/wrls_theseus.png';
import ImageNews3 from './assets/images/news/wrls_battle.png';
import ImageNews5 from './assets/images/news/wrls_wallpapers.png';
import wrlsMartianX from './assets/images/news/wrls_martian_x.png';

SwiperCore.use([Navigation]);

function Stage3({ setLocale }) {
  const intl = useIntl();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 940px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 940px)")
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      <div className="content">
        {matches && <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
          <FormattedMessage id="play-now" />
        </a>}

        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <Social />

        <div className="wrapper">
          <div className="flex">
            <div className="left">
              <div className="left-wrapper">
                <div className="subtitle">
                  <FormattedMessage id="stage-3-subtitle" />
                </div>
                <div className="title">
                  <FormattedMessage id="stage-3-title" />
                </div>
                <div className="text">
                  <FormattedMessage
                    id="stage-3-text"
                    values={{
                      br: <br />,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="video-ui" />
              <iframe
                src="https://www.youtube.com/embed/n5mM00_3i_Y" title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className={intl.locale === 'ru' ? "video-text ru" : "video-text"}>
                <FormattedMessage id="stage-3-yellow-line" />
              </div>
            </div>
          </div>
          <div className="yellow-line" />
        </div>

        {!matches && <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
          <FormattedMessage id="play-now" />
        </a>}

        <div className="news">
          <div className={intl.locale === 'ru' ? "news-title ru" : "news-title"}>
            <FormattedMessage id="slider-title" />
          </div>

          <div className="slider-wrapper">
            <div className="ui-left" />
            <div className="ui-right" />

            <div className="navigation">
              <div ref={prevRef} className="prev">
                <SvgPrev />
              </div>
              <div ref={nextRef} className="next">
                <SvgNext />
              </div>
            </div>

            <Swiper
              spaceBetween={20}
              slidesPerView={1.5}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: nextRef.current ? nextRef.current : undefined,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.update();
              }}
              observer={true}
              breakpoints={{
                460: {
                  slidesPerView: 2.2,
                },
                490: {
                  slidesPerView: 2.5,
                },
                580: {
                  slidesPerView: 3,
                },
                680: {
                  slidesPerView: 3.5,
                },
                860: {
                  slidesPerView: 4,
                }
              }}
            >
              <SwiperSlide>
                <a className="news-item" href="https://youtu.be/BjYl9aSqNyo">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews2} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="news-2" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="https://www.youtube.com/watch?v=ZKiVEsxq1es">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews3} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-3" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="https://photos.app.goo.gl/nPijro2jMqxcipmp6">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews5} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-5" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="https://www.youtube.com/watch?v=55iACh5Dmtg">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={wrlsMartianX} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-2" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="https://www.youtube.com/watch?v=His_AkQoQSk">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={wrlsMartianX} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-2-android" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

      </div>
      <Footer setLocale={setLocale} />
    </>
  );
}

export default Stage3;