import React, { useEffect, useState } from 'react';
import Footer from "./components/Footer";
import { FormattedMessage } from "react-intl";
import moment from 'moment-timezone';

import Logo from './assets/images/logo.svg';


const CountDown = ({ deadline }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTimeUntil = (deadline) => {
    const time = moment.tz(deadline, "Europe/Moscow").diff(moment());

    if (time < 0) {
      setSeconds(0);
      setMinutes(0);
      setHours(0);
      setDays(0);
    } else {
      setSeconds(Math.floor((time / 1000) % 60));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    }
  }

  const leadingZero = (num) => num < 10 ? "0" + num : num;

  useEffect(() => {
    getTimeUntil(deadline);

    setInterval(() => getTimeUntil(deadline), 1000);
  }, [deadline]);

  return (
    <div className="counter">
      <div className="counter-item">
        {leadingZero(days)}
        <span><FormattedMessage id="days" /></span>
      </div>

      <div className="separator" />

      <div className="counter-item">
        {leadingZero(hours)}
        <span><FormattedMessage id="hours" /></span>
      </div>

      <div className="separator" />

      <div className="counter-item">
        {leadingZero(minutes)}
        <span><FormattedMessage id="minutes" /></span>
      </div>

      <div className="separator" />

      <div className="counter-item">
        {leadingZero(seconds)}
        <span><FormattedMessage id="seconds" /></span>
      </div>
    </div>
  );
}

function Stage1({ setLocale }) {
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, [])

  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>

          {init && <CountDown deadline="2022-04-28T17:00:00" />}

          <div className="content-frame">
            <img src={require('./assets/images/frame-top.png')} alt="top" />
            <p><FormattedMessage id="stage-1-text" values={{ br: <br /> }} /></p>
            <img src={require('./assets/images/frame-bottom.png')} alt="bottom" />
          </div>

          <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
            <FormattedMessage id="play-now" />
          </a>
        </div>
      </div>
      <Footer setLocale={setLocale} />
    </>
  );
}

export default Stage1;