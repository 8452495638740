import React from 'react';
import { useIntl } from "react-intl";

import FB from '../assets/images/fb.svg';
import Twitter from '../assets/images/twitter.svg';
import VK from '../assets/images/vk.svg';
import YouTube from '../assets/images/youtube.svg';
import Reddit from '../assets/images/reddit.svg';
import Discord from '../assets/images/discord.svg';

function Social() {
  const intl = useIntl();

  return (
    <div className="social">
      {(intl.locale === 'en' || intl.locale === 'es') && (
        <a href="https://www.facebook.com/warrobots" className="social-item">
          <div className="icon">
            <img src={FB} alt="fb" />
          </div>
          Facebook
        </a>
      )}
      {intl.locale === 'jp' && (
        <a href="https://twitter.com/warrobotsjp" className="social-item">
          <div className="icon">
            <img src={Twitter} alt="twitter" />
          </div>
          Twitter
        </a>
      )}
      {intl.locale === 'ru' && (
        <a href="https://vk.com/warrobots" className="social-item">
          <div className="icon">
            <img src={VK} alt="vk" />
          </div>
          VK
        </a>
      )}
      <a href="https://www.youtube.com/c/WALKINGWARROBOTS" className="social-item">
        <div className="icon">
          <img src={YouTube} alt="youtube" />
        </div>
        Youtube
      </a>
      {intl.locale === 'en' && (
        <a href="https://www.reddit.com/r/walkingwarrobots/" className="social-item">
          <div className="icon">
            <img src={Reddit} alt="reddit" />
          </div>
          Reddit
        </a>
      )}
      {intl.locale !== 'ru' && (
        <a href="https://discord.gg/warrobots" className="social-item">
          <div className="icon">
            <img src={Discord} alt="discord" />
          </div>
          Discord
        </a>
      )}
    </div>
  );
}

export default Social;