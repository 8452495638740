import React, { useEffect, useRef, useState } from 'react';
import Footer from "./components/Footer";
import Social from "./components/Social";
import { FormattedMessage, useIntl } from "react-intl";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/scss';

import { ReactComponent as SvgPrev } from './assets/images/arrow-left.svg';
import { ReactComponent as SvgNext } from './assets/images/arrow-right.svg';

import Logo from './assets/images/logo.svg';
import Youtube from './assets/images/youtube-icon.svg';
import YoutubeHover from './assets/images/youtube-icon-hover.svg';
import YoutubeActive from './assets/images/youtube-icon-active.svg';

import ImageNews1 from './assets/images/news/wrls_theseus.png';
import ImageNews2 from './assets/images/news/wrls_martian_x.png';
import ImageNews3 from './assets/images/news/wrls_battle.png';
import ImageNews5 from './assets/images/news/wrls_wallpapers.png';

SwiperCore.use([Navigation]);

function Stage4({ setLocale }) {
  const intl = useIntl();
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 940px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 940px)")
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      <div className="content">
        {matches && <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
          <FormattedMessage id="play-now" />
        </a>}

        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <Social />

        <div className="wrapper">
          <div className="title-wrapper">
            <a href="#">
              <img
                alt="Youtube"
                src={Youtube}
                onMouseOver={(e) => (e.currentTarget.src = YoutubeHover)}
                onMouseOut={(e) => (e.currentTarget.src = Youtube)}
                onMouseDown={(e) => (e.currentTarget.src = YoutubeActive)}
              />
            </a>
            <div className="title">
              <span><FormattedMessage id="stage-4-title" /></span>
              <div className="subtitle">
                <FormattedMessage
                  id="stage-4-subtitle"
                  values={{
                    br: <br />,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className={intl.locale === 'ru' ? "grid ru" : "grid"}>
              <div className="item">
                <div className="number yellow">
                  100500+
                </div>
                <div className="title">
                  <FormattedMessage id="stage-4-grid-1" />
                </div>
              </div>

              <div className="item">
                <div className="number">
                  365
                </div>
                <div className="title">
                  <FormattedMessage id="stage-4-grid-2" />
                </div>
              </div>

              <div className="item">
                <div className="number yellow">
                  100500+
                </div>
                <div className="title">
                  <FormattedMessage id="stage-4-grid-3" />
                </div>
              </div>

              <div className="item">
                <div className="number">
                  4.7 <span>million</span>
                </div>
                <div className="title">
                  <FormattedMessage id="stage-4-grid-4" />
                </div>
              </div>

              <div className="item">
                <div className="number yellow">
                  1050+
                </div>
                <div className="title">
                  <FormattedMessage id="stage-4-grid-5" />
                </div>
              </div>

              <div className="item">
                <div className="number">
                  1442
                </div>
                <div className="title">
                  <FormattedMessage id="stage-4-grid-6" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!matches && <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
          <FormattedMessage id="play-now" />
        </a>}

        <div className="news">
          <div className="news-title">
            <FormattedMessage id="stage-4-slider-title" />
          </div>

          <div className="slider-wrapper">
            <div className="ui-left" />
            <div className="ui-right" />

            <div className="navigation">
              <div ref={prevRef} className="prev">
                <SvgPrev />
              </div>
              <div ref={nextRef} className="next">
                <SvgNext />
              </div>
            </div>

            <Swiper
              spaceBetween={20}
              slidesPerView={1.5}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: nextRef.current ? nextRef.current : undefined,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.update();
              }}
              observer={true}
              breakpoints={{
                460: {
                  slidesPerView: 2.2,
                },
                490: {
                  slidesPerView: 2.5,
                },
                580: {
                  slidesPerView: 3,
                },
                680: {
                  slidesPerView: 3.5,
                },
                860: {
                  slidesPerView: 4,
                }
              }}
            >
              <SwiperSlide>
                <a className="news-item" href="#">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews1} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-1" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="#">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews2} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-2" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="#">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews3} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-3" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a className="news-item" href="https://photos.app.goo.gl/nPijro2jMqxcipmp6">
                  <div className="news-item-wrapper">
                    <div className="image">
                      <img src={ImageNews5} alt="image" />
                    </div>
                    <div className="text">
                      <FormattedMessage id="stage-4-slider-item-5" />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Footer setLocale={setLocale} />
    </>
  );
}

export default Stage4;