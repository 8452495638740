import React, { useEffect, useState } from 'react';
import Footer from "./components/Footer";
import Social from "./components/Social";
import { FormattedMessage } from "react-intl";

import Logo from './assets/images/logo.svg';
import Point from './assets/images/point_active.svg';

function Stage2({ setLocale }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 940px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 940px)")
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      <div className="content">
        {matches && <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
          <FormattedMessage id="play-now" />
        </a>}

        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <Social />

        <div className="video-frame">
          <iframe
            src="https://www.youtube.com/embed/n5mM00_3i_Y" title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div className="points">
          <div className="point">
            <div className="point-icon">
              <img src={Point} alt="point" />
            </div>
            <FormattedMessage id="stage-2-game-news" values={{ br: <br /> }} />
          </div>
          <div className="point">
            <div className="point-icon">
              <img src={Point} alt="point" />
            </div>
            <FormattedMessage id="stage-2-community" />
          </div>
          <div className="point">
            <div className="point-icon">
              <img src={Point} alt="point" />
            </div>
            <FormattedMessage id="stage-2-stories" />
          </div>
          <div className="point">
            <div className="point-icon">
              <img src={Point} alt="point" />
            </div>
            <FormattedMessage id="stage-2-freebies" />
          </div>
        </div>

        {!matches && <a href="https://trk.mail.ru/c/lje2d9?mt_sub1=cm_LiveShow22&mt_network=pixonic_community" className="play-button">
          <FormattedMessage id="play-now" />
        </a>}
      </div>
      <Footer setLocale={setLocale} />
    </>
  );
}

export default Stage2;