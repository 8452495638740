import React, { useEffect, useState } from "react";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import Stage4 from "./Stage4";
import { IntlProvider } from "react-intl";

import './assets/css/style.scss';

import messagesEn from "./i18n/en.json";
import messagesRu from "./i18n/ru.json";
import messagesEs from "./i18n/es.json";
import messagesJp from "./i18n/jp.json";

const messagesByLang = {
  en: messagesEn,
  ru: messagesRu,
  es: messagesEs,
  jp: messagesJp,
};

function App() {
  const [stage, setStage] = useState(3);
  const [locale, setLocale] = useState("en");

  useEffect(() => {
    const stageParams = (new URLSearchParams(window.location.search)).get('stage');
    if (stageParams) {
      setStage(Number(stageParams));
    }
  }, []);

  useEffect(() => {
    if ([1, 2, 3, 4].includes(stage)) {
      document.getElementById('body').className = `stage-${stage}`;
    }
  }, [stage]);

  return (
    <IntlProvider locale={locale} messages={messagesByLang[locale]}>
      {stage === 1 && <Stage1 setLocale={setLocale} />}
      {stage === 2 && <Stage2 setLocale={setLocale} />}
      {stage === 3 && <Stage3 setLocale={setLocale} />}
      {stage === 4 && <Stage4 setLocale={setLocale} />}
    </IntlProvider>
  )
}

export default App;